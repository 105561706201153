/* eslint-disable import/no-unresolved */
<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <validation-observer ref="simpleRules">
    <b-form @submit.prevent>
      <b-row>
        <b-col md="9">
          <Fieldset id="information" :title="$t('Infomation General')">
            <b-row>
              <b-col cols="6">
                <b-form-group :label="`*` + $t('Full name') + `:`" label-cols-md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Full name"
                    rules="required"
                  >
                    <b-form-input
                      v-model="model.fullname"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Full name')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group :label="`*` + $t('Phone') + `:`" label-cols-md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="phone"
                    rules="required"
                  >
                    <b-form-input
                      v-model="model.phone"
                      :state="errors.length > 0 ? false : null"
                      type="number"
                      :formatter="formatPhone"
                      placeholder="Phone"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="*Email:" label-cols-md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required"
                  >
                    <b-form-input
                      v-model="model.email"
                      :state="errors.length > 0 ? false : null"
                      type="text"
                      placeholder="Email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group :label="`*` + $t('Birthday') + `:`" label-cols-md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Birthday"
                    rules="required"
                  >
                    <!-- <label for="example-datepicker">Choose a date</label> -->
                    <!-- <b-form-datepicker
                      v-model="model.birthday"
                      :state="errors.length > 0 ? false : null"
                      class="mb-1"
                      :placeholder="$t('Birthday')"
                      :date-format-options="{
                        day: 'numeric',
                        month: 'numeric',
                        year: 'numeric',
                      }"
                      locale="vi"
                    /> -->
                    <flat-pickr
                      v-model="model.birthday"
                      class="form-control"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group :label="`*` + $t('Gender') + `:`" label-cols-md="12">
              <div class="d-flex align-items-center">
                <b-form-radio v-model="model.gender" :value="1">
                  {{ $t('Male') }}
                </b-form-radio>
                <b-form-radio v-model="model.gender" :value="0" class="ml-3">
                  {{ $t('Female') }}
                </b-form-radio>
              </div>
            </b-form-group>
          </Fieldset>
          <Fieldset id="information" :title="$t('Address')">
            <b-form-group :label="`*` + $t('Address') + `:`" label-cols-md="12">
              <validation-provider
                #default="{ errors }"
                name="Address"
                rules="required"
              >
                <b-form-input
                  v-model="model.address"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('Address')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-row>
              <b-col cols="12">
                <!-- <b-form-group :label="$t('Country')" label-cols-md="12">
                  <v-select
                    v-model="model.country"
                    label="name"
                    :reduce="x => x.id"
                    :options="provinceOptions"
                    :placeholder="$t('Country')"
                  />
                </b-form-group> -->
                <b-form-group :label="$t('Province/City')" label-cols-md="12">
                  <v-select
                    v-model="model.province"
                    label="name"
                    :reduce="x => x.id"
                    :options="provinceOptions"
                    :placeholder="$t('Province/City')"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group :label="$t('District')" label-cols-md="12">
                  <v-select
                    v-model="model.district"
                    label="name"
                    :reduce="x => x.id"
                    :options="districtOptions"
                    :placeholder="$t('District')"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group :label="$t('Wards')" label-cols-md="12">
                  <v-select
                    v-model="model.ward"
                    label="name"
                    :reduce="x => x.id"
                    :options="wardOptions"
                    :placeholder="$t('Wards')"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </Fieldset>
          <Fieldset id="information" :title="$t('Company Info')">
            <b-form-group :label="$t('Company')" label-cols-md="12">
              <validation-provider
                #default="{ errors }"
                name="Company"
              >
                <b-form-input
                  v-model="model.company"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('Company')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group :label="$t('Address for issuing red invoice')" label-cols-md="12">
              <validation-provider
                #default="{ errors }"
                name="Address for issuing red invoice"
              >
                <b-form-input
                  v-model="model.address_red"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('Address for issuing red invoice')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-row>
              <b-col cols="6">
                <b-form-group :label="$t('Tax code')" label-cols-md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Tax code"
                  >
                    <b-form-input
                      v-model="model.tax_code"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Tax code')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group :label="$t('ID number')" label-cols-md="12">
                  <validation-provider
                    #default="{ errors }"
                    name="idnumb"
                  >
                    <b-form-input
                      v-model="model.cccd"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('ID number')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </Fieldset>
        </b-col>
        <b-col md="3">
          <div class="position-sticky top">
            <Fieldset id="avatar" :title="$t('Avatar')">
              <FeatureImage
                :avatar="model.avatar"
                @previewFiles="previewFiles"
              />
            </Fieldset>
            <Fieldset id="avatar1" :title="$t('Additional information')">
              <b-form-group :label="$t('Note')" label-cols-md="12">
                <validation-provider
                  #default="{ errors }"
                  name="taxcode"
                  rules="required"
                >
                  <b-form-input
                    v-model="model.note"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Note')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </Fieldset>
            <div class="text-right">
              <b-button
                variant="primary"
                type="submit"
                class="text-right"
                @click.prevent="validationForm"
              >
                {{ $t('Create new') }}
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  // BFormDatepicker,
  BFormRadio,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // BFormDatepicker,
    BFormRadio,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      status: null,
      provinceOptions: [],
      districtOptions: [],
      wardOptions: [],
      required,
      model: {
        avatar: this.avatarDefault(),
        fullname: null,
        email: null,
        birthday: null,
        gender: 1,
        is_active: true,
        phone: null,
        address: null,
        status_local: null,
        country: null,
        district: null,
        province: null,
        ward: null,
        company: null,
        taxcode: null,
        idnumb: null,
        note: null,
      },
    }
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    'model.province'() {
      this.loadDistrict(this.model.province)
    },
    // eslint-disable-next-line object-shorthand
    'model.district'() {
      this.loadWards(this.model.district)
    },
  },
  created() {
    this.loadProvince()
  },
  methods: {
    formatPhone(e) {
      return String(e).substring(0, 10)
    },
    async validationForm() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            const translation = {}
            translation[await this.getEditLanguage()] = {
              fullname: this.model.fullname,
              address: this.model.address,
            }
            const params = {
              site_id: JSON.parse(localStorage.getItem('siteID')).id,
              gender: Number(this.model.gender),
              is_active: Number(this.model.is_active === true ? 1 : 0),
              birthday: (this.model.birthday),
              email: this.model.email,
              phone: this.model.phone,
              fullname: this.model.fullname,
              address: this.model.address,
              province: this.model.province,
              district: this.model.district,
              ward: this.model.ward,
              company: this.model.company,
              address_red: this.model.address_red,
              cccd: this.model.cccd,
              tax_code: this.model.tax_code,
              note: this.model.note,
            }
            if (this.model.avatar) {
              params.avatar = this.model.avatar
            }
            const res = await Request.post(
              this.$http,
              `${process.env.VUE_APP_API_URL}/customers`,
              params,
            )
            if (res.status === 200) {
              if (res.data.status) {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Congratulation !',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: 'Create Customer success',
                  },
                })
                this.$router.push('/customer/list')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Opps! Something wrong',
                    icon: 'AlertOctagonIcon',
                    variant: 'danger',
                    text: String(this.showError(res.data.error, ',')),
                  },
                })
              }
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(error),
              },
            })
          }
        }
      })
    },
    async loadProvince() {
      const res = await this.mixGet('/provinces', { limit: 64 })
      if (res.status) {
        // eslint-disable-next-line array-callback-return
        res.data.items.map(item => {
          this.provinceOptions.push({
            ...item,
            name: `${item.prefix} ${item.name}`,
          })
        })
      }
    },
    async loadDistrict(provinceID = null) {
      let param = {}
      if (provinceID) {
        param = {
          limit: 200,
          province_id: provinceID,
        }
      }
      const res = await this.mixGet('/districts', param)
      if (res.status) {
        // eslint-disable-next-line array-callback-return
        res.data.items.map(item => {
          this.districtOptions.push({
            ...item,
            name: `${item.prefix} ${item.name}`,
          })
        })
      }
    },
    async loadWards(districtID = null) {
      let param = {}
      if (districtID) {
        param = {
          limit: 200,
          district_id: districtID,
        }
      }
      const res = await this.mixGet('/wards', param)
      if (res.status) {
        // eslint-disable-next-line array-callback-return
        res.data.items.map(item => {
          this.wardOptions.push({
            ...item,
            name: `${item.prefix} ${item.name}`,
          })
        })
      }
    },
    previewFiles(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.model.avatar = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fade-browse {
  border: 1px dashed var(--primary);
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
</style>

<style lang="scss" scoped>
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fade-browse {
  border: 1px dashed var(--primary);
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
</style>
