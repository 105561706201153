import { render, staticRenderFns } from "./CustomerAdd.vue?vue&type=template&id=04cad2ae&scoped=true&"
import script from "./CustomerAdd.vue?vue&type=script&lang=js&"
export * from "./CustomerAdd.vue?vue&type=script&lang=js&"
import style0 from "./CustomerAdd.vue?vue&type=style&index=0&id=04cad2ae&prod&lang=scss&"
import style1 from "./CustomerAdd.vue?vue&type=style&index=1&id=04cad2ae&prod&lang=scss&scoped=true&"
import style2 from "./CustomerAdd.vue?vue&type=style&index=2&id=04cad2ae&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04cad2ae",
  null
  
)

export default component.exports