var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"9"}},[_c('Fieldset',{attrs:{"id":"information","title":_vm.$t('Infomation General')}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Full name') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Full name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Full name')},model:{value:(_vm.model.fullname),callback:function ($$v) {_vm.$set(_vm.model, "fullname", $$v)},expression:"model.fullname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Phone') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number","formatter":_vm.formatPhone,"placeholder":"Phone"},model:{value:(_vm.model.phone),callback:function ($$v) {_vm.$set(_vm.model, "phone", $$v)},expression:"model.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"*Email:","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"text","placeholder":"Email"},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Birthday') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Birthday","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",model:{value:(_vm.model.birthday),callback:function ($$v) {_vm.$set(_vm.model, "birthday", $$v)},expression:"model.birthday"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Gender') + ":","label-cols-md":"12"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-radio',{attrs:{"value":1},model:{value:(_vm.model.gender),callback:function ($$v) {_vm.$set(_vm.model, "gender", $$v)},expression:"model.gender"}},[_vm._v(" "+_vm._s(_vm.$t('Male'))+" ")]),_c('b-form-radio',{staticClass:"ml-3",attrs:{"value":0},model:{value:(_vm.model.gender),callback:function ($$v) {_vm.$set(_vm.model, "gender", $$v)},expression:"model.gender"}},[_vm._v(" "+_vm._s(_vm.$t('Female'))+" ")])],1)])],1),_c('Fieldset',{attrs:{"id":"information","title":_vm.$t('Address')}},[_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Address') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Address')},model:{value:(_vm.model.address),callback:function ($$v) {_vm.$set(_vm.model, "address", $$v)},expression:"model.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Province/City'),"label-cols-md":"12"}},[_c('v-select',{attrs:{"label":"name","reduce":function (x) { return x.id; },"options":_vm.provinceOptions,"placeholder":_vm.$t('Province/City')},model:{value:(_vm.model.province),callback:function ($$v) {_vm.$set(_vm.model, "province", $$v)},expression:"model.province"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('District'),"label-cols-md":"12"}},[_c('v-select',{attrs:{"label":"name","reduce":function (x) { return x.id; },"options":_vm.districtOptions,"placeholder":_vm.$t('District')},model:{value:(_vm.model.district),callback:function ($$v) {_vm.$set(_vm.model, "district", $$v)},expression:"model.district"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Wards'),"label-cols-md":"12"}},[_c('v-select',{attrs:{"label":"name","reduce":function (x) { return x.id; },"options":_vm.wardOptions,"placeholder":_vm.$t('Wards')},model:{value:(_vm.model.ward),callback:function ($$v) {_vm.$set(_vm.model, "ward", $$v)},expression:"model.ward"}})],1)],1)],1)],1),_c('Fieldset',{attrs:{"id":"information","title":_vm.$t('Company Info')}},[_c('b-form-group',{attrs:{"label":_vm.$t('Company'),"label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Company"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Company')},model:{value:(_vm.model.company),callback:function ($$v) {_vm.$set(_vm.model, "company", $$v)},expression:"model.company"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Address for issuing red invoice'),"label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Address for issuing red invoice"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Address for issuing red invoice')},model:{value:(_vm.model.address_red),callback:function ($$v) {_vm.$set(_vm.model, "address_red", $$v)},expression:"model.address_red"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Tax code'),"label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Tax code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Tax code')},model:{value:(_vm.model.tax_code),callback:function ($$v) {_vm.$set(_vm.model, "tax_code", $$v)},expression:"model.tax_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('ID number'),"label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"idnumb"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('ID number')},model:{value:(_vm.model.cccd),callback:function ($$v) {_vm.$set(_vm.model, "cccd", $$v)},expression:"model.cccd"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('div',{staticClass:"position-sticky top"},[_c('Fieldset',{attrs:{"id":"avatar","title":_vm.$t('Avatar')}},[_c('FeatureImage',{attrs:{"avatar":_vm.model.avatar},on:{"previewFiles":_vm.previewFiles}})],1),_c('Fieldset',{attrs:{"id":"avatar1","title":_vm.$t('Additional information')}},[_c('b-form-group',{attrs:{"label":_vm.$t('Note'),"label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"taxcode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Note')},model:{value:(_vm.model.note),callback:function ($$v) {_vm.$set(_vm.model, "note", $$v)},expression:"model.note"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"text-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" "+_vm._s(_vm.$t('Create new'))+" ")])],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }